import React, { useState, useMemo } from "react";
import Sidebar from "../../components/layout/Sidebar/Sidebar.tsx";
import Header from "../../components/layout/Header/Header.tsx";
import Footer from "../../components/layout/Footer/Footer.tsx";
import AddNewSiteModal from "../../components/Modals/AddNewSiteModal.tsx";
import EditNewSiteModal from "../../components/Modals/EditNewSiteModal.tsx";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.tsx";
import { Button, Box } from "@mui/material";
import GenericComplexTable from "../../components/GenericComplexTable/GenericComplexTable.tsx";
import { usePortfolioTable } from "../../utils/api/usePortfolioTable.ts";
import { usePortfoliosDropdown } from "../../utils/api/usePortfoliosDropdown.ts";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal.tsx";
import useScreenSize from "../../utils/hooks/useScreenSize.ts";
import { useAuth } from "../../context/auth.context.tsx";
import AddNewPortfolioModal from "../../components/Modals/AddNewPortfolioModal.tsx"; 

const Portfolios: React.FC = () => {
  const screenSize = useScreenSize();
  const { logOutAction } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddPortfolioModalOpen, setIsAddPortfolioModalOpen] = useState(false);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [siteToDelete, setSiteToDelete] = useState<string | null>(null); 
  const [siteToEdit, setSiteToEdit] = useState<any | null>(null);

  const {
    data: portfolioTableData,
    isLoading,
    isFetching,
    isError,
  } = usePortfolioTable(null);
  const { data: portfolios } = usePortfoliosDropdown(null);

  const filteredTableData = useMemo(() => {
    if (selectedPortfolios.length === 0 || !portfolioTableData) return [];

    return portfolioTableData.filter((row: any) => {
      return selectedPortfolios.includes(row.portfolio_name);
    });
  }, [selectedPortfolios, portfolioTableData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenEditModal = (row: any) => {
    setSiteToEdit(row);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSiteToEdit(null);
  };

  const handleDropdownChange = (newValue: string[]) => {
    setSelectedPortfolios(newValue);
  };

  const extractIdFromUrl = (url: string): string => {
    const parts = url.split('/');
    for (let i = parts.length - 1; i >= 0; i--) {
      if (parts[i]) {
        return parts[i];
      }
    }
    return '';
  };

  const handleDeleteRow = (row: any) => {
    const id = extractIdFromUrl(row.url); 
    setSiteToDelete(id); 
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSiteToDelete(null);
  };

  const handleOpenAddPortfolioModal = () => {
    setIsAddPortfolioModalOpen(true);
  };

  const handleCloseAddPortfolioModal = () => {
    setIsAddPortfolioModalOpen(false); 
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidebar />
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Header text="Portfolios" onLogout={logOutAction}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2E7C67",
              color: "#FFFFFF",
            }}
            size="small"
            onClick={handleOpenAddPortfolioModal}
          >
            Add a New Portfolio
          </Button>
        </Header>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          p={2}
          bgcolor="#F5F5F5"
          borderRadius="4px"
          width="50%"
        >
          <CustomDropdown
            Title="Select Portfolio"
            Options={(portfolios || []).map((portfolio) => portfolio.name)}
            onChangeValue={handleDropdownChange}
          />
          <Button
            variant="outlined"
            style={{
              color: "#2E7C67",
              borderColor: "#2E7C67",
              backgroundColor: "#FFFFFF",
            }}
            onClick={handleOpenModal}
          >
            Add a New Site
          </Button>
        </Box>

        <Box m={2}>
          <GenericComplexTable
            title="Portfolio Table"
            tableData={filteredTableData}
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            columns={[
              { accessorKey: "sam_id", header: "SAM ID" },
              { accessorKey: "name", header: "Name" },
              { accessorKey: "latitude", header: "Latitude" },
              { accessorKey: "longitude", header: "Longitude" },
              { accessorKey: "portfolio_name", header: "Portfolio Name" },
              { accessorKey: "dc_kw", header: "DC kW" },
              { accessorKey: "ac_kw", header: "AC kW" },
              { accessorKey: "modified_on", header: "Modified On" },
            ]}
            maxHeight={"400px"}
            maxWidth={screenSize.width - 180}
            enableExport={true}
            enableBottomToolbar={true}
            onEditRow={handleOpenEditModal}
            onDeleteRow={handleDeleteRow}
          />
        </Box>

        <Footer />
      </div>
      <AddNewSiteModal open={isModalOpen} onClose={handleCloseModal} />
      {siteToEdit && (
        <EditNewSiteModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          siteData={siteToEdit}
        />
      )}
      {siteToDelete && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          id={siteToDelete} 
        />
      )}

      <AddNewPortfolioModal
        open={isAddPortfolioModalOpen}
        onClose={handleCloseAddPortfolioModal}
      />
    </div>
  );
};

export default Portfolios;
