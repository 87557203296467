import React, { useContext, useState, type ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = React.createContext<any | null>(null);

export const AuthProvider: React.FC<{
  children: ReactNode[] | ReactNode;
}> = ({ children }) => {
  const [session, setSession] = useState<any>(null);
  const navigate = useNavigate();

  const logInAction = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}auth/login/`,
        data
      );
      if (response.data) {
        setSession(response.data);
        navigate("/");
        return;
      }
    } catch (err) {
      alert("Please provide a valid input");
    }
  };

  const logOutAction = () => {
    setSession(null);
    navigate("/login");
  };

  const value = {
    session,
    logInAction,
    logOutAction,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
