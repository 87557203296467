import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h4>Internal Registries</h4>
        <nav>
          <ul>
            <li><a href="https://docs.google.com/spreadsheets/d/1HyGm0tw-8DG5IumfmdS4qgvdm7TSy2VHIbIMiuxd0fg/edit?gid=0#gid=0">Asset Registry Data</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1B99XEYn6ahBSLpZiBt2SOHzjlcgXhQCpb-pvT8r-mIY/edit?gid=844148369#gid=844148369">COD Data</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1xHvMP9eLVy5Dcl1Ky4qBzxg9YzHhbj1BbMd1oideMZ0/edit?gid=1870907883#gid=1870907883">Early Stage Projects</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1En9i7d0GAriwQ_pBFi3IzoAXNxEM0KMRvyrUavUCxuI/edit?gid=802694209#gid=802694209">ASD Residential Registry</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1is3pcvxcjs6r7BDIv0Hy6TBLTQ-3XSDTvh2Wz73VEO0/edit?gid=452481579#gid=452481579">ECHO Residential Registry</a></li>
          </ul>
        </nav>
      </div>
      <div className="footer-column">
        <h4>External Registries</h4>
        <nav>
          <ul>
            <li><a href="https://docs.google.com/spreadsheets/d/1PtHgVZI4vby4XN-w-X5qPa6Ezjd-oFS1wUN6jRpliGc/edit#gid=0">Terraform Solar</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1lCSPx1rE3pjaUfQslMX4VSaoW9b6zzxMqK3EXNIvGQ4/edit?gid=2106588974#gid=2106588974">TransAlta Early Construction Registry</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1Xy1NTiZKTWfNkTY7Cbhp0hQxYPYtuVa92o9L6MFUAGI/edit?gid=183251865#gid=183251865">TransAlta Operational Registry</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1Ny9g2ffyxfia0L7g8gi9j_FhWT6LyJE2vfMVzCX58BM/edit#gid=1608941901">SSI Operation</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1frQr2mlGCiPAO_-ItuYMfosa2fglJcDX6clkMJTM5-8/edit#gid=1946931156">SSI Construction</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/11RabNde36ucY_Mqg40AisCaMQsSKO3lsEAbR4U8uX6I/edit?gid=1608941901#gid=1608941901">Scout</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1DzwJuhtPT_MVfTXZHfosKwv9pNNRdQ5ID4dLSw7WuRw/edit?gid=1608941901#gid=1608941901">Urban Grid</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1JQqaQOYgQUW6mEhi1_ndL7pFQtjPBu0kZBS-1Rx73-c/edit?gid=1608941901#gid=1608941901">BEP US</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/14OAojLVWTsVDYLFNvTJN0HBvaFmLmeErg3G1JN0s9ro/edit?gid=1608941901#gid=1608941901">Deriva Energy</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1Ksr2GS1ieAls-Q-TMhtYbvCN0BFPVVkMzhSeIW7VVfc/edit?gid=1608941901#gid=1608941901">X-ELIO</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/13pVUPrwrVVqnpK78FyULGeMjYIxpHXoyPFnKPMDrE7k/edit?gid=1608941901#gid=1608941901">Open Fleet 3</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/11jpYuKsONDjcTghDTDkNoi5mXuB2lGCSorqZW4G-dK4/edit?gid=0#gid=0">Open Fleet 4</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1DkqokZNXVlNqutMOBr67XqQnDTpXzMjgrlVMVpnUyY0/edit?gid=0#gid=0">Open Fleet 5</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1RIwV1IFKjNVxf4X3E79FrZpnlvk5Bk1jGCXLN0y4LRQ/edit?gid=0#gid=0">Open Fleet 6</a></li>
            </ul>
        </nav>
      </div>
      <div className="footer-column">
        <h4>Limited Registry</h4>
        <nav>
          <ul>
            <li><a href="https://docs.google.com/spreadsheets/d/1nhfunzciuD_9jL6H-CUwbZvxxnDEyqwRQBHIFMeLUPQ/edit#gid=1622921192">M&A Operations</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1Vh2INW-J-uJ7e_DhqNuwWJSf_ylvyTRCfPlZhczytSM/edit#gid=881434869">M&A Early Construction Registry</a></li>
          </ul>
        </nav>
      </div>
      <div className="footer-column">
        <h4>Analysis Tools</h4>
        <nav>
          <ul>
            <li><a href="https://bigquerygeoviz.appspot.com/">BigQuery Geo Viz Map Tool</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1jv0WyfxIDwAaHf5CJUP8W0xqYYe-Fr6k0dM2DhR0jJ8/edit?gid=1580221257#gid=1580221257">Parcel Coordinates Data</a></li>
            <li><a href="https://geojson.io/#map=2/0/20">Geojson.io Coordinates Create/Edit Tool</a></li>
            <li><a href="https://brookfieldrenewable.cloud.looker.com/dashboards/asset_registry::qf_map">QF Map</a></li>
            <li><a href="https://retool.luminace.com/apps/75a03472-e45b-11ed-9633-ebc11f9d436d/Asset%20Intake/Asset%20Intake%20Landing%20Page">Asset Intake Application</a></li>
            <li><a href="https://source.cloud.google.com/hawkeye-operational-asset-mgmt/gcp-hawkeye-source-repo-data_foundation/+/master:Distance_analysis_v2/Reference_Sql_Statement_For_Land_Parcels.md">Land Parcel BigQuery Geo Viz Guide</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
