import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";

const transformPortfolioTable = (response: any) => {
  const data = response.data;

  return data.map((item: any) => ({
    url: item.url,
    sam_id: item.sam_id,
    name: item.name,
    latitude: item.latitude,
    longitude: item.longitude,
    portfolio_name: item.portfolio_name,
    dc_kw: item.dc_kw,
    ac_kw: item.ac_kw,
    modified_on: new Date(item.modified_on).toLocaleDateString(), 
  }));
};

const fetchPortfolioTable = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}sites/`
  );
  return response;
};

// add enabled by sessionUser here

export function usePortfolioTable(session: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["portfolioTable"],
    queryFn: async () => await fetchPortfolioTable(axiosAuth),
    select: (data) => transformPortfolioTable(data),
    refetchOnWindowFocus: false,
  });
}
