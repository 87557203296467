import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEditSite } from "../../utils/api/editSite.ts";
import { useNotification } from "../../context/notification.context.tsx";

interface EditNewSiteModalProps {
  open: boolean;
  onClose: () => void;
  siteData: any;
}

const EditNewSiteModal: React.FC<EditNewSiteModalProps> = ({
  open,
  onClose,
  siteData,
}) => {
  const { mutate: editSite, isLoading } = useEditSite();
  const { addNotification } = useNotification();

  const [formData, setFormData] = useState({
    sam_id: siteData.sam_id || "",
    name: siteData.name || "",
    city: siteData.city || "",
    state: siteData.state || "",
    latitude: siteData.latitude || "",
    longitude: siteData.longitude || "",
    portfolio_name: siteData.portfolio_name || "",
    docket_id: siteData.docket_id || "",
    dc_kw: siteData.dc_kw || "",
    ac_kw: siteData.ac_kw || "",
    polygon: siteData.polygon || "",
    ac_source: siteData.ac_source || "",
    dc_source: siteData.dc_source || "",
  });

  useEffect(() => {
    if (siteData) {
      setFormData({
        sam_id: siteData.sam_id || "",
        name: siteData.name || "",
        city: siteData.city || "",
        state: siteData.state || "",
        latitude: siteData.latitude || "",
        longitude: siteData.longitude || "",
        portfolio_name: siteData.portfolio_name || "",
        docket_id: siteData.docket_id || "",
        dc_kw: siteData.dc_kw || "",
        ac_kw: siteData.ac_kw || "",
        polygon: siteData.polygon || "",
        ac_source: siteData.ac_source || "",
        dc_source: siteData.dc_source || "",
      });
    }
  }, [siteData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "dc_kw" || name === "ac_kw"
          ? parseFloat(value) || ""
          : value,
    });
  };

  const handleSubmit = () => {
    const idFromUrl = siteData.url.match(/\/sites\/(\d+)\//)?.[1];

    if (
      idFromUrl &&
      formData.sam_id &&
      formData.name &&
      formData.dc_kw &&
      formData.ac_kw
    ) {
      editSite(
        { ...formData, id: idFromUrl },
        {
          onSuccess: () => {
            addNotification("The site was edited successfully", true);
            onClose();
          },
          onError: (error) => {
            console.error("Failed to edit site", error);
            addNotification("Failed to edit the site", false);
          },
        }
      );
    } else {
      console.error("Missing required fields or ID");
    }
  };

  const sourceOptions = [
    "Early_stage_projects",
    "Asset_Registry",
    "COD_Tracker",
    "SSI_Constr_Early_Stage",
    "SSI_Operational_registry",
    "M&A_operations",
    "utility_fleet",
    "TransAlta_operations",
    "ECHO_Residential",
    "ASD_Residential",
    "Deriva_Energy",
    "X-ELIO",
    "BEP_US",
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          outline: "none",
        }}
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            EDIT SITE
          </Typography>
          {siteData?.sam_id && (
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "8px",
              }}
            >
              {siteData.sam_id}
            </Typography>
          )}
          <Box
            component="form"
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              label="Site Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
            <TextField
              label="Latitude"
              variant="outlined"
              fullWidth
              name="latitude"
              value={formData.latitude}
              onChange={handleChange}
            />
            <TextField
              label="Longitude"
              variant="outlined"
              fullWidth
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}
            />
            <TextField
              label="Portfolio"
              variant="outlined"
              fullWidth
              name="portfolio"
              value={formData.portfolio_name}
              onChange={handleChange}
            />
            <TextField
              label="QF Docket"
              variant="outlined"
              fullWidth
              name="docket_id"
              value={formData.docket_id}
              onChange={handleChange}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="DC kW"
                variant="outlined"
                fullWidth
                name="dc_kw"
                value={formData.dc_kw}
                onChange={handleChange}
              />
              <TextField
                label="AC kW"
                variant="outlined"
                fullWidth
                name="ac_kw"
                value={formData.ac_kw}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <label htmlFor="polygon">Polygon</label>
              <textarea
                id="polygon"
                name="polygon"
                value={formData.polygon}
                onChange={handleChange}
                style={{
                  width: "100%",
                  minHeight: "100px",
                  border: "1px solid #ccc",
                  padding: "8px",
                  borderRadius: "4px",
                  fontFamily: "inherit",
                  fontSize: "16px",
                  resize: "none",
                }}
              />
            </Box>
            <TextField
              select
              label="AC Source"
              variant="outlined"
              fullWidth
              name="ac_source"
              value={formData.ac_source}
              onChange={handleChange}
            >
              {sourceOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="DC Source"
              variant="outlined"
              fullWidth
              name="dc_source"
              value={formData.dc_source}
              onChange={handleChange}
            >
              {sourceOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                style={{
                  color: "#2E7C67",
                  borderColor: "#2E7C67",
                  backgroundColor: "#FFFFFF",
                }}
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#2E7C67", color: "#FFFFFF" }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Save Site
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditNewSiteModal;
