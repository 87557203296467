import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const addNewSite = async (axiosAuth: AxiosInstance, siteData: any) => {
  const response = await axiosAuth.post(
    `${process.env.REACT_APP_API_BASE_URL}sites/`, 
    siteData
  );
  return response.data;
};

export function useAddNewSite() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (siteData: any) => addNewSite(axiosAuth, siteData),
    onSuccess: () => {
      queryClient.invalidateQueries(["portfolioTable"]); 
    },
  });
}
