import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";

const transformPortfoliosDropdown = (response: any) => {
    return response.data; 
  };

const fetchPortfoliosDropdown = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}portfolios/`
  );
  return response;
};

// enable user session later

export function usePortfoliosDropdown(session: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["portfoliosDropdown"],
    queryFn: async () => await fetchPortfoliosDropdown(axiosAuth),
    select: (data) => transformPortfoliosDropdown(data),
    refetchOnWindowFocus: false,
  });
}
