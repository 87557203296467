import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";

const transformSites = (response: any) => {
  return response.data;
};

const fetchSites = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}sites/ui_list/`
  );
  return response;
};

export function useSites(session: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["sites"],
    queryFn: async () => await fetchSites(axiosAuth),
    select: (data) => transformSites(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}
